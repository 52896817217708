import React, { useEffect } from 'react'
import { signoutRedirectCallback, signinRedirect } from 'utils/oidcClientExtension';

function SignoutOidc() {
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback()
      signinRedirect()
    }
    signoutAsync()
  }, [])

  return (<div></div>)
}

export default SignoutOidc
