import { EventStatus } from "components/Events/event-status";

export const isReadOnly = (status) =>
    status === EventStatus.RECEIVED ||
    status === EventStatus.SCHEDULED ||
    status === EventStatus.MISSING;

export const hasValue = (value) =>
    !(value === null || value === undefined || value === [] || value === "");

export const countFormItems = (itemGroups) =>
    itemGroups.reduce(
        (itemCount, itemGroup) => itemCount + itemGroup.items.length,
        0
    );

export const isDateTimeItem = (itemType) =>
    itemType === "Date" || itemType === "DateAndTime" || itemType === "Time";

export const isTextItem = (itemType) =>
    itemType === "ParagraphText" ||
    itemType === "SingleLineText" ||
    itemType === "Number";

export const isStaticItemType = (itemType) =>
    itemType === "StaticText" || itemType === "SectionBreak";

const getMaxDate = (maxValue, eventDate, studyEventId) => {
    if (!maxValue) {
        throw Error("Date item maxValue not set");
    }

    const isEventDate =
        maxValue === "EventDate" && studyEventId > 0 && eventDate;

    return isEventDate ? new Date(eventDate) : new Date();
};

const hasMaxDate = (maxValue) =>
    maxValue === "Today" || maxValue === "EventDate";

export const setMaxDate = (
    itemGroups,
    { EventDate: eventDate, StudyEventId: studyEventId }
) => {
    for (const itemGroup of itemGroups) {
        for (const item of itemGroup.items) {
            const maxValue = item.validation.maxValue || item.validation.max;

            if (
                isDateTimeItem(item.itemType) &&
                hasMaxDate(item.validation.maxValue)
            ) {
                item.maxDate = getMaxDate(maxValue, eventDate, studyEventId);
            }
        }
    }
};

export const getDeviceInformation = () => {
    //Note: screen.orientation is not well supported by browsers
    //var screenOrientation = screen.orientation != null ? screen.orientation.type : null;

    var mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    var screenOrientation = mql.matches ? "portrait" : "landscape";

    return {
        screenOrientation: screenOrientation,
        viewportHeight: window.innerHeight,
        viewportWidth: window.innerWidth,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
    };
};

const isRequiredItemWithoutValue = (item, formData) => {
    const itemHasNoValue = !hasValue(formData[item.oid]);
    const isNotStatic = !isStaticItemType(item.itemType);
    const isRequired =
        item.validation.required === true || item.validation.required.value;
    const isVisible = item.isVisible;

    return itemHasNoValue && isNotStatic && isRequired && isVisible;
};

export const getItemsWithoutValue = (itemGroups, formData) =>
    itemGroups
        .filter((ig) => ig.isVisible)
        .reduce(
            (acc, ig, i) => [
                ...acc,
                ...ig.items
                    .filter((it) => isRequiredItemWithoutValue(it, formData))
                    .map((item) => ({
                        page: i + 1,
                        item,
                    })),
            ],
            []
        );
