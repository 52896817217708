import { createVisibilityFunctions, updateVisibility } from "./form-visibility";
import { convertFormValidation } from "./convertFormValidation";
import { setMaxDate } from "./form-helpers";

export const types = {
    SET_FORM: "SET_FORM",
    SET_ITEM_VALUE: "SET_ITEM_VALUE",
};

const actions = {
    [types.SET_FORM]: (form) => {
        const visibilityFunctions = createVisibilityFunctions(form);

        setMaxDate(form.itemGroups, form.identifierModel);
        convertFormValidation(form);
        updateVisibility(form, visibilityFunctions);

        return {
            form,
            visibilityFunctions,
        };
    },
    [types.SET_ITEM_VALUE]: ({ id, value, format }, state) => {
        const updatedState = {
            ...state,
            form: {
                ...state.form,
                formData: {
                    ...state.form.formData,
                    [id]: value,
                    ...(format !== undefined && { [`${id}__format`]: format }),
                },
            },
        };

        updateVisibility(updatedState.form, updatedState.visibilityFunctions);

        return updatedState;
    },
};

export function formReducer(state, { type, payload }) {
    const action = actions[type];

    if (!action) {
        throw new Error(`Action type ${type} does not exist`);
    }

    return action(payload, state);
}
