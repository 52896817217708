import "./Dashboard.css";

import PropTypes from "prop-types";
import classNames from "classnames";

import { EventsTile } from "./tiles/EventsTile/EventsTile";
import { NextEventTile } from "./tiles/NextEventTile/NextEventTile";
import { StartUnscheduledQuestionnaireTile } from "./tiles/StartUnscheduledTile/StartUnscheduledTile";
import { VideoCallTile } from "./tiles/VideoCallTile/VideoCallTile";
import { FetchError } from "components/Base/FetchError/FetchError";

export function Dashboard({
  className,
  setFetchError,
  showFetchError,
  showVideoTile,
  studyLocked,
}) {
  return (
    <div
      className={classNames({
        [className]: className !== "",
        dashboard: true,
      })}
    >
      {showFetchError && (
        <div className="dashboard__header">
          <FetchError />
        </div>
      )}
      <NextEventTile setFetchError={setFetchError} studyLocked={studyLocked} />
      <StartUnscheduledQuestionnaireTile studyLocked={studyLocked} />
      <EventsTile setFetchError={setFetchError} />
      {showVideoTile && <VideoCallTile setFetchError={setFetchError} />}
    </div>
  );
}

Dashboard.propTypes = {
  className: PropTypes.string,
  setFetchError: PropTypes.func.isRequired,
  showFetchError: PropTypes.bool,
  showVideoTile: PropTypes.bool.isRequired,
  studyLocked: PropTypes.bool.isRequired,
};
